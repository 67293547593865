import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Header, Footer } from '../components/Common'
import { PageSection, BannerImage } from '../components/Layout'
import styles from './about.module.scss'

const AboutPage = ({ data }) => {
  const pageData = data.allPrismicAboutPage.nodes[0].data
  const commonData = data.allPrismicCommonPageContent.nodes[0].data

  return (
    <div>
      <Header logoUrl={commonData.logo_image.url} />
      <BannerImage
        imageFluid={pageData.banner_image.localFile.childImageSharp.fluid}
        titleHtml={pageData.page_title.html}
      />
      <PageSection>
        <div
          className={styles.contentContainer}
          dangerouslySetInnerHTML={{ __html: pageData.page_content.html }}
        />
      </PageSection>
      <Footer data={commonData.footer_text_content.html} />
    </div>
  )
}

export const query = graphql`
  query AboutPageQuery {
    allPrismicCommonPageContent {
      nodes {
        data {
          footer_text_content {
            html
          }
          logo_image {
            url
          }
        }
      }
    }
    allPrismicAboutPage {
      nodes {
        data {
          page_title {
            html
          }
          banner_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          page_content {
            html
          }
          call_to_action_text {
            html
          }
        }
      }
    }
  }
`

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage
